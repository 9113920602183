import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutInner from "./aboutpage/aboutuspage/AboutInner";
import Breadcrumb from "./aboutpage/aboutuspage/Breadcrumb";
import { useHistory, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

import {
  ApiPost,
  GetSearchParams,
  ProcessLoginToken,
  AnalyticEvent,
  GenerateAuthenticationHash,
} from "../Developer";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [formState, setFormState] = useState(0);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChage = (event) => {
    setpassword(event.target.value);
  };

  const performLogin = (event) => {
    event.preventDefault();
    authenticate();
  };

  const recaptchaChange = (value) => {    
    setRecaptchaValue(value);
  };

  const authenticate = () => {
    if(!recaptchaValue){
      setError("Complete the Recaptcha to continue");
      return;
    }
    setFormState(1);
    setError("");
    GenerateAuthenticationHash(username, password).then((hashedPassword) => {
      ApiPost("auth/authenticate", { username, password: hashedPassword, recaptcha: recaptchaValue })
        .then((response) => {
          if (response.ok) {
            response.json().then((jsonResult) => {
              ProcessLoginToken(jsonResult.tokenOrMessage);
              AnalyticEvent("Authentication", "Authentication Success");
              history.push("/");
            });
          } else {
            AnalyticEvent("Authentication", "Authentication Failure");
            response.json().then((jsonResult) => {
              setError(jsonResult.tokenOrMessage);
              console.log(jsonResult);
            });
            setFormState(0);
          }
        })
        .catch((error) => {
          setFormState(0);
          console.log(error);
        });
    });
  };

  const formState1 = (
    <div
      style={{
        paddingTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px 0px 0px",
        }}
      >
         <p>Login to your Heroes Raid Account, this is the same account you use to login to the game.</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px 0px 0px",
        }}
      >
      <p style={{ color: "Red" }}>{error}</p>
      </div>
      <form>
        <div
          className="column"
          style={{
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="newsletter-form-grp" style={{ margin: "20px" }}>
            <i className="far fa-login" />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div className="newsletter-form-grp" style={{ margin: "20px" }}>
            <i className="far fa-login" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChage}
            />
          </div>
          <ReCAPTCHA sitekey="6LfQnXMkAAAAAFJehM93FRLqHi1h5bPluWYa7wIf" onChange={recaptchaChange} />
          <button onClick={performLogin} style={{ margin: "20px" }}>
            LOGIN <i className="fas fa-login" />
          </button>
        </div>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0px",
          paddingRight: "40px",
        }}
      >
        <p>
          <Link to="/register">
            <strong>Create Account</strong>
          </Link>
        </p>
      </div>
    </div>
  );

  const formState2 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0px 0px",
          minHeight: "700px",
        }}
      >
        <svg className="loadingspinner" viewBox="0 0 50 50">
          <circle
            className="spinnerpath"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <Breadcrumb>
        <h2>
          <span>Login</span>
        </h2>
      </Breadcrumb>
      <main>
        <div className="container" style={{ minHeight: "700px" }}>
          <div className="newsletter-form" style={{ margin: "7.5px 0px" }}>
            {formState === 0 ? formState1 : formState2}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Login;
