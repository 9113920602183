import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import NewsItem from "./NewsItem"
import { ApiGet } from "../Developer";
import Discord from "./Discord";

function BlogArea() {
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    ApiGet(`resource/news?start=0&count=3`)
      .then((response) => {
        if (response.ok) {
          response.json().then((jsonResult) => {
            console.log(jsonResult);
            setNewsItems(jsonResult.results);
          });
        } else {
          //handle error
        }
      })
      .catch((error) => console.log(error));
  }, []);


  return (
	<section className="blog-area pt-115 pb-120">
            <div className="container">
              <div className="row justify-content-center">
              <iframe src="https://store.steampowered.com/widget/1416800/" frameBorder="0" width="646" height="190"></iframe>
                <div className="col-xl-6 col-lg-8" style={{paddingTop:"100px"}}>
                  <div className="section-title title-style-three text-center mb-70">
                    <h2>Latest <span>News</span></h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
               {
                (newsItems && newsItems.map((newsItem, index) => {
                    return ( <NewsItem binding={newsItem} isHomePage={true} key={index} />);
                }))
               }
            
              </div>
              <div className="row justify-content-center">
              <Discord />
              </div>
            </div>
          </section>
  )
}

export default BlogArea