import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import Twitch from "../../Twitch";
import { SocialIcon } from "react-social-icons";
import ReactTooltip from "react-tooltip";
import { AnalyticEvent } from '../../Developer';

const rumble = {
  icon: "M 47.800781 48.300781 L 39.300781 37 C 43.699219 35.199219 46.800781 30.898438 46.800781 25.898438 C 46.800781 19.300781 41.398438 13.898438 34.699219 13.898438 L 21.898438 13.898438 C 21.800781 13.898438 21.800781 13.898438 21.800781 13.898438 L 19.601562 13.898438 C 19.199219 13.898438 18.800781 14.199219 18.800781 14.699219 L 18.800781 48.699219 C 18.800781 49.101562 19.101562 49.5 19.601562 49.5 L 27 49.5 C 27.398438 49.5 27.800781 49.199219 27.800781 48.699219 L 27.800781 37.898438 L 30.699219 37.898438 L 39.199219 49.199219 C 39.398438 49.398438 39.601562 49.5 39.800781 49.5 L 47.199219 49.5 C 47.5 49.5 47.800781 49.300781 47.898438 49.101562 C 48 48.800781 47.898438 48.5 47.800781 48.300781 Z M 34.101562 30.898438 C 34 30.898438 33.898438 30.898438 33.699219 30.898438 L 27.800781 30.898438 C 27.800781 30.898438 27.699219 30.898438 27.699219 30.898438 C 27.699219 30.898438 27.601562 30.800781 27.601562 30.800781 L 27.601562 21.300781 C 27.601562 21.199219 27.601562 21.199219 27.699219 21.199219 C 27.699219 21.199219 27.699219 21.199219 27.800781 21.199219 C 27.800781 21.199219 27.800781 21.199219 27.898438 21.199219 L 33.800781 21.199219 C 33.898438 21.199219 34.101562 21.199219 34.199219 21.199219 C 36.601562 21.398438 38.5 23.5 38.5 26.101562 C 38.5 28.5 36.5 30.699219 34.101562 30.898438 Z M 34.101562 30.898438",
  mask: "M0,0v64h64V0H0zM 47.800781 48.300781 L 39.300781 37 C 43.699219 35.199219 46.800781 30.898438 46.800781 25.898438 C 46.800781 19.300781 41.398438 13.898438 34.699219 13.898438 L 21.898438 13.898438 C 21.800781 13.898438 21.800781 13.898438 21.800781 13.898438 L 19.601562 13.898438 C 19.199219 13.898438 18.800781 14.199219 18.800781 14.699219 L 18.800781 48.699219 C 18.800781 49.101562 19.101562 49.5 19.601562 49.5 L 27 49.5 C 27.398438 49.5 27.800781 49.199219 27.800781 48.699219 L 27.800781 37.898438 L 30.699219 37.898438 L 39.199219 49.199219 C 39.398438 49.398438 39.601562 49.5 39.800781 49.5 L 47.199219 49.5 C 47.5 49.5 47.800781 49.300781 47.898438 49.101562 C 48 48.800781 47.898438 48.5 47.800781 48.300781 Z M 34.101562 30.898438 C 34 30.898438 33.898438 30.898438 33.699219 30.898438 L 27.800781 30.898438 C 27.800781 30.898438 27.699219 30.898438 27.699219 30.898438 C 27.699219 30.898438 27.601562 30.800781 27.601562 30.800781 L 27.601562 21.300781 C 27.601562 21.199219 27.601562 21.199219 27.699219 21.199219 C 27.699219 21.199219 27.699219 21.199219 27.800781 21.199219 C 27.800781 21.199219 27.800781 21.199219 27.898438 21.199219 L 33.800781 21.199219 C 33.898438 21.199219 34.101562 21.199219 34.199219 21.199219 C 36.601562 21.398438 38.5 23.5 38.5 26.101562 C 38.5 28.5 36.5 30.699219 34.101562 30.898438 Z M 34.101562 30.898438",
  color: "#85C742",
};

const patreon = {
  icon: "M 21.5 16.101562 L 21.5 50.5 L 15.199219 50.5 L 15.199219 16.101562 Z M 38.101562 16.101562 C 45.199219 16.101562 51 21.898438 51 29 C 51 36.101562 45.199219 41.898438 38.101562 41.898438 C 31 41.898438 25.199219 36.101562 25.199219 29 C 25.199219 21.898438 31 16.101562 38.101562 16.101562 Z M 38.101562 16.101562 ",
  mask: "M0,0v64h64V0H0z M 21.5 16.101562 L 21.5 50.5 L 15.199219 50.5 L 15.199219 16.101562 Z M 38.101562 16.101562 C 45.199219 16.101562 51 21.898438 51 29 C 51 36.101562 45.199219 41.898438 38.101562 41.898438 C 31 41.898438 25.199219 36.101562 25.199219 29 C 25.199219 21.898438 31 16.101562 38.101562 16.101562 Z M 38.101562 16.101562 ",
  color: "#FF5900",
};

const socialTrackingEvent = (socialName) => {
   AnalyticEvent('Social Link Click' , socialName);
}

function DeveloperStreamInner() {
  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
      loop: Infinity,
    }).init();
  }, []);
  return (
    <section className="inner-about-area fix">
      <div className="container">
        <Twitch />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
            gap: "10px",
            marginTop: "32px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              gap: "10px",
            }}
          >
            <SocialIcon
              data-tip
              data-for="tooltip-youtube"
              url="https://youtube.com/@aaron.lionheart"
              label="YouTube"
              onClick={() => socialTrackingEvent('YouTube')}
            />
            <ReactTooltip
              id="tooltip-youtube"
              place="top"
              type="dark"
              effect="solid"
            >
              YouTube
            </ReactTooltip>
            <SocialIcon
              data-tip
              data-for="tooltip-instagram"
              url="https://www.instagram.com/aaron.lionheart/"
              label="Instagram"
              onClick={() => socialTrackingEvent('Instagram')}
            />
            <ReactTooltip
              id="tooltip-instagram"
              place="top"
              type="dark"
              effect="solid"
            >
              Instagram
            </ReactTooltip>
            <SocialIcon
              data-tip
              data-for="tooltip-twitch"
              url="https://www.twitch.tv/aaronlionheart"
              label="Twitch"
              onClick={() => socialTrackingEvent('Twitch')}
            />
            <ReactTooltip
              id="tooltip-twitch"
              place="top"
              type="dark"
              effect="solid"
            >
              Twitch
            </ReactTooltip>
            <SocialIcon
              data-tip
              data-for="tooltip-rumble"
              url="https://rumble.com/c/aaronlionheart"
              defaultSVG={rumble}
              label="Rumble"
              onClick={() => socialTrackingEvent('Rumble')}
            />
            <ReactTooltip
              id="tooltip-rumble"
              place="top"
              type="dark"
              effect="solid"
            >
              Rumble
            </ReactTooltip>
            <SocialIcon
              data-tip
              data-for="tooltip-twitter"
              url="https://twitter.com/AaronLionheart8"
              label="Twitter"
              onClick={() => socialTrackingEvent('Twitter')}
            />
            <ReactTooltip
              id="tooltip-twitter"
              place="top"
              type="dark"
              effect="solid"
            >
              Twitter
            </ReactTooltip>
            <SocialIcon
              data-tip
              data-for="tooltip-patreon"
              url="https://www.patreon.com/aaronlionheart"
              defaultSVG={patreon}
              label="Patreon"
              onClick={() => socialTrackingEvent('Patreon')}
            />
            <ReactTooltip
              id="tooltip-patreon"
              place="top"
              type="dark"
              effect="solid"
            >
              Patreon
            </ReactTooltip>
            <SocialIcon
              data-tip
              data-for="tooltip-tiktok"
              url="https://www.tiktok.com/@aaronlionheart"
              bgColor="#C4003D"
              label="TikTok"
              onClick={() => socialTrackingEvent('TikTok')}
            />
            <ReactTooltip
              id="tooltip-tiktok"
              place="top"
              type="dark"
              effect="solid"
            >
              TikTok
            </ReactTooltip>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DeveloperStreamInner;
