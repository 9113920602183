import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import { AnalyticEvent } from '../../../Developer';

function AboutInner() {
  const [emailError, setEmailError] = useState("");
  const [fetchError, setFetchError] = useState("");
  const [email, setEmail] = useState("");
  const [formState, setFormState] = useState(0);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  async function postData(url = "", data = {}) {
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.ok;
    } catch {
      return false;
    }
  }

  const sendMessage = () => {
    setEmailError("");
    setFetchError("");
    let errors = false;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      AnalyticEvent("Email", "Alpha Signup Invalid Email");
      errors = true;
    }

    if (errors) {
      return;
    }

    setFormState(1);
    postData("https://api.heroesraid.com/email/subscriptions/subscribe/alpha", {
      email: email,
    }).then((success) => {
      if (success) {
        setFormState(2);
        AnalyticEvent("Email", "Alpha Signup Success");
      } else {
        setFetchError(
          "There was an error communicating with the target server, try again later."
          );
        setFormState(0);
        AnalyticEvent("Email", "Alpha Signup Failure");
      }
    });
  };
  const formState1 = (
    <div className="cta-action" style={{ margin: "20px 0px" }}>
         <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px",
        }}
      >
      <p style={{ fontSize: "12pt", margin: "0px", color: "black", padding: "10px 40px"}}>
        Enter your email to subscribe to the Heroes Raid Alpha release.
      </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px",
        }}
      >
        <div className="newsletter-form">
          <form>
            <div className="newsletter-form-grp">
              <i className="far fa-envelope" />
              <input
                type="email"
                placeholder="Enter your email..."
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <button onClick={sendMessage}>
              Sign Up <i className="fas fa-envelope" />
            </button>
          </form>
        </div>
        <br />
        <p style={{ fontSize: "9pt", margin: "0px", color: "#ffc107" }}>
          {emailError}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px",
        }}
      >
        <p style={{ fontSize: "9pt", margin: "0px", color: "#ffc107" }}>
          {fetchError}
        </p>
      </div>
    </div>
  );

  const formState2 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "92px 0px",
        }}
      >
        <svg className="loadingspinner" viewBox="0 0 50 50">
          <circle
            className="spinnerpath"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </>
  );

  const formState3 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "87.5px 0px",
        }}
      >
        <p style={{ fontSize: "12pt", margin: "0px", color: "black", padding: "10px 40px"}}>
          You have been signed-up for the Heroes Raid Alpha, Thank you.
        </p>
      </div>
    </>
  );

  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
      loop: Infinity,
    }).init();
  }, []);
  return (
    <section className="inner-about-area fix">
      <div className="container">
        <div
          className="row align-items-center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {formState === 0
            ? formState1
            : formState === 1
            ? formState2
            : formState3}
        </div>
      </div>
    </section>
  );
}

export default AboutInner;
