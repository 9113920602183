import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DeveloperStreamInner from "../developerstream/DeveloperStreamInner";
import Breadcrumb from "../aboutpage/aboutuspage/Breadcrumb";
import { ApiGet, GetSearchParams } from "../../Developer";
import NewsItem from "../../components/NewsItem";
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router-dom';

const News = (props) => {
  const itemsPerPage = 6;
  const categoryClassNameBase = "btn btn-style-two";
  const categoryClassNameSelected = "btn btn-style-two btn-style-two-selected";

  const initialSearchParams = GetSearchParams();

  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const searchParams = GetSearchParams();
  const searchParamCategory = searchParams.category;
  const history = useHistory();

  const [eventCategoryClass, setEventCategoryClass] = useState(
     searchParamCategory === "event"
        ? categoryClassNameSelected
        : categoryClassNameBase
  );
  const [newsCategoryClass, setNewsCategoryClass] = useState(
    searchParamCategory === "news"
        ? categoryClassNameSelected
        : categoryClassNameBase
  );
  const [announcementCategoryClass, setAnnouncementCategoryClass] = useState(
    searchParamCategory === "announcement"
        ? categoryClassNameSelected
        : categoryClassNameBase
  );
  const [patchNotesCategoryClass, setPatchNotesCategoryClass] = useState(
    searchParamCategory === "patchnotes"
    ? categoryClassNameSelected
    : categoryClassNameBase
  );
  const [allCategoryClass, setAllCategoryClass] = useState(
    !searchParamCategory
        ? categoryClassNameSelected
        : categoryClassNameBase
  );
  const [searchTerm, setSearchTerm] = useState(
    initialSearchParams.search ? initialSearchParams.search : ""
  );

  const setCategoryButtonClasses = () => {
    const searchParams = GetSearchParams();
    const searchParamCategory = searchParams.category;

    setEventCategoryClass(
      searchParamCategory === "event"
        ? categoryClassNameSelected
        : categoryClassNameBase
    );
    setNewsCategoryClass(
      searchParamCategory === "news"
        ? categoryClassNameSelected
        : categoryClassNameBase
    );
    setAnnouncementCategoryClass(
      searchParamCategory === "announcement"
        ? categoryClassNameSelected
        : categoryClassNameBase
    );
    setPatchNotesCategoryClass(
      searchParamCategory === "patchnotes"
        ? categoryClassNameSelected
        : categoryClassNameBase
    );
    setAllCategoryClass(
      !searchParamCategory
        ? categoryClassNameSelected
        : categoryClassNameBase
    );
  };

  const onClickCategoryButton = (newCategory) => {
    let params = new URLSearchParams(window.location.search);
    params.set("category", newCategory);
    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${params.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchNews();
    setCategoryButtonClasses();
    window.scrollTo(0, 0);
  };

  const performSearch = (event) => {
    event.preventDefault();
    let params = new URLSearchParams(window.location.search);
    params.set("search", searchTerm);
    params.set("page", 1);
    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${params.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    fetchNews();
    window.scrollTo(0, 0);
  };

  const setPageParam = (page) => {
    let params = new URLSearchParams(window.location.search);
    params.set("page", page);
    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${params.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchNews = () => {
    const searchParams = GetSearchParams();
    const searchParamPage = searchParams.page ? searchParams.page : 1;
    const searchParamCategory = searchParams.category
      ? searchParams.category
      : "";
    const searchParamTerm = searchParams.search ? searchParams.search : "";

    setCurrentPage(searchParamPage - 1);
    setSearchTerm(searchParamTerm);

    ApiGet(
      `resource/news?start=${
        searchParamPage * itemsPerPage - itemsPerPage
      }&count=${itemsPerPage}&categoryFilter=${searchParamCategory}&searchTerm=${searchParamTerm}`
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((jsonResult) => {
            setNewsItems(jsonResult.results);
            setPageCount(Math.ceil(jsonResult.totalNewsItems / itemsPerPage));
          });
        } else {
          //handle error
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => history.listen(() => {
    setCategoryButtonClasses();
    const searchParams = GetSearchParams();
    const searchParamPage = searchParams.page ? searchParams.page : 1;
    const searchParamTerm = searchParams.search ? searchParams.search : "";
    setCurrentPage(searchParamPage - 1);
    setSearchTerm(searchParamTerm);
  }), [])

  useEffect(() => {
    setCategoryButtonClasses();
    fetchNews();
  }, []);

  const handlePageClick = (event) => {
    setPageParam(event.selected + 1);
    fetchNews();
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Header />
      <Breadcrumb>
        <h2>
          {" "}
          <span>News</span>
        </h2>
      </Breadcrumb>
      <main>
        <section className="blog-area pb-120">
          <div className="container">
            <div
              className="row justify-content-center"
              style={{ paddingTop: "20px" }}
            >
              <div className="newsletter-form" style={{ margin: "7.5px 0px" }}>
                <form>
                  <div className="newsletter-form-grp">
                    <i className="far fa-search" />
                    <input
                      type="search"
                      placeholder="Search.."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <button onClick={performSearch}>
                    SEARCH <i className="fas fa-search" />
                  </button>
                </form>
              </div>
            </div>
            <div
              className="row justify-content-center"
              style={{ paddingBottom: "40px" }}
            >
              <button
                onClick={() => onClickCategoryButton("")}
                className={allCategoryClass}
              >
                All
              </button>
              <button
                onClick={() => onClickCategoryButton("news")}
                className={newsCategoryClass}
              >
                News
              </button>
              <button
                onClick={() => onClickCategoryButton("event")}
                className={eventCategoryClass}
              >
                Event
              </button>
              <button
                onClick={() => onClickCategoryButton("announcement")}
                className={announcementCategoryClass}
              >
                Announcement
              </button>
              <button
                onClick={() => onClickCategoryButton("patchnotes")}
                className={patchNotesCategoryClass}
              >
                Patch Notes
              </button>
            </div>
            <div className="row justify-content-center">
              {newsItems &&
                newsItems.map((newsItem, index) => {
                  return <NewsItem binding={newsItem} key={index} />;
                })}
            </div>
            <div className="row justify-content-center">
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default News;
