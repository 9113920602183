import { BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Shop from "./pages/store/Shop";
import Contact from "./pages/contact/Contact";
import Community from "./pages/community/Community";
import Overview from "./pages/overview/Overview";
import AlphaSignup from "./pages/AlphaSignup";
import NewsItem from "./pages/NewsItem";
import DeveloperStream from "./pages/DeveloperStream";
import Upcoming from "./pages/aboutpage/upcoming/Upcoming";
import GameSingle from "./pages/aboutpage/singlegame/GameSingle";
import Blogs from "./pages/blogs/Blogs";
import BlogDetails from "./pages/blogdetails/BlogDetails";
import News from "./pages/news/News";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ReleaseTimeline from "./pages/ReleaseTimeline";
import CreateNews from "./pages/news/editor/CreateNews";
import ScrollToTopRoute from "./components/scrolltotop/ScrollToTopRoute";
import ReactGA from "react-ga4";
import { ProcessCookies } from "./Developer";

ReactGA.initialize("G-WPYYT2X2GD");

// import Loader from "react-js-loader";

function App() {
  //  const [loading,setLoading] = useState(false);

  //  useEffect(()=>{
  //   setLoading(true)
  //   setTimeout(()=>{
  //     setLoading(false)
  //   },8000)
  //  },[])
  //  {
  //   loading ?(
  //     <MoonLoader color={color} loading={loading} css={override} size={150} />
  //   )

  // }    
  ProcessCookies();
  console.log(window.user);
  ReactGA.pageview(window.location.pathname);
  return (
    <div className="App">
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path="/">
            <Home />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/login">
            <Login />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/register">
            <Register />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/alpha">
            <AlphaSignup />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/developer-stream">
            <DeveloperStream />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/news">
            <News />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/news/:id">
            <NewsItem />
          </ScrollToTopRoute>   
          <ScrollToTopRoute exact={true} path="/create-news">
            <CreateNews />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/release-schedule">
            <ReleaseTimeline />
          </ScrollToTopRoute>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
