import React from 'react';
import Breadcrumb from './Breadcrumb';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import GameSingleArea from './GameSingleArea';

function Overview() {
  return (
	<>
		<Header/>
		<main>
			<Breadcrumb/>
			<GameSingleArea/>
		</main>
		<Footer/>
	</>
  )
}

export default Overview