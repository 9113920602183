import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutInner from './aboutpage/aboutuspage/AboutInner'
import Breadcrumb from './aboutpage/aboutuspage/Breadcrumb'

function AlphaSignUp() {
  return (
	<>
	<Header/>
	<Breadcrumb>
	<h2>Alpha <span>Sign-up</span></h2>
	</Breadcrumb>
	<main>
	<AboutInner/>	
	</main>
	<Footer/>
	</>
  )
}

export default AlphaSignUp
