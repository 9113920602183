import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import ReactGA from "react-ga4";

//const endpoint = "https://localhost:5001/";
const endpoint = 'https://api.heroesraid.com/';

export const AnalyticEvent = (category, action) => {
  ReactGA.event({
    category: category,
    action: action
  });
};

export const Logout = () => {
  const cookies = new Cookies();
  cookies.remove("token", { path: "/" });
  console.log(cookies.get("token"));
};

const AppendAuthorizationHeader = (headers) => {
  if (window.user && window.user.token) {
    headers.Authorization = `Bearer ${window.user.token}`;
  }
  return headers;
};

export const ProcessLoginToken = (token) => {
  const user = { token, jwt: jwt_decode(token) };
  window.user = user;
  console.log(user);
  const cookies = new Cookies();
  cookies.set("token", token, { path: "/" });
  console.log(cookies.get("token"));
};

export const ProcessCookies = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (token) {
    const user = { token, jwt: jwt_decode(token) };
    window.user = user;
    console.log(user);
  }
};

export async function ApiPost(url = "", data = {}) {
  try {
    const response = await fetch(`${endpoint}${url}`, {
      method: "post",
      headers: AppendAuthorizationHeader({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if(response.status === 400 || response.status === 401  || response.status === 429 ){
        return response;
      }
      else{
        return Promise.reject((data && data.message) || response.status);
      }
    } else {
      return response;
    }
  } catch {
    return null;
  }
}

export async function ApiGet(url = "") {
  try {
    const response = await fetch(`${endpoint}${url}`, {
      method: "get",
    });
    if (!response.ok) {
      return Promise.reject((data && data.message) || response.status);
    } else {
      return response;
    }
  } catch {
    return null;
  }
}

export async function ApiPostFormData(url = "", formData) {
  try {
    const response = await fetch(`${endpoint}${url}`, {
      method: "post",
      headers: AppendAuthorizationHeader({}),
      body: formData,
    });
    if (!response.ok) {
      return Promise.reject((data && data.message) || response.status);
    } else {
      return await response.json();
    }
  } catch {
    return null;
  }
}

export const GetSearchParams = () => {
  return new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
};


async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export const GenerateAuthenticationHash = async (username, password) => {
  let hashedUsername = await sha256(username.toUpperCase().trim());
  let hashedPassword = await sha256(password.trim());

  return (hashedUsername + hashedPassword).toUpperCase().trim();
};