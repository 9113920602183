import React, { useRef, useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import DeveloperStreamInner from "../../developerstream/DeveloperStreamInner";
import Breadcrumb from "../../aboutpage/aboutuspage/Breadcrumb";
import { Editor } from "@tinymce/tinymce-react";
import { ApiPostFormData, ApiPost, ProcessCookies, AnalyticEvent } from "../../../Developer";

const headerStyle = {
  marginTop: "32px",
  marginBottm: "0",
};

function News() {
  const summaryEditorRef = useRef(null);
  const bodyEditorRef = useRef(null);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsThumbnail, setNewsThumbnail] = useState(null);
  const [newsCategory, setNewsCategory] = useState("Announcement");
  const [summaryContent, setSummaryContent] = useState("");
  const [bodyContent, setBodyContent] = useState("");
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (window.user && window.user.jwt && window.user.jwt.role) {
      setHasAccess(window.user.jwt.role === "2");
    }
  }, []);

  const handleSubmit = async (e) => {
    console.log(newsTitle);
    console.log(newsThumbnail);
    console.log(newsCategory);
    console.log(summaryContent);
    console.log(bodyContent);
    e.preventDefault();

    //first pass upload image and get a ref
    let thumbnailLocation = "";

    const formData = new FormData();
    formData.append("image", newsThumbnail, newsThumbnail.name);

    await ApiPostFormData("developer/post/image", formData)
      .then((response) => {
        var responseJson = response;
        thumbnailLocation = `https://api.heroesraid.com${responseJson.location}`;
      })
      .catch((error) => alert(error));

    await ApiPost("developer/post/news", {
      Title: newsTitle,
      Thumbnail: thumbnailLocation,
      Category: newsCategory,
      Summary: summaryContent,
      Body: bodyContent,
    })
      .then((response) => {
        var responseJson = response;
        if (response.ok) {
          AnalyticEvent('Admin Event', 'Create News Success')
          alert("done");
        } else {
          AnalyticEvent('Admin Event', 'Create News Failure')
          alert("failed");
        }
      })
      .catch((error) => alert(error));
  };

  const imageUploader = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", blobInfo.blob(), blobInfo.filename());

      ApiPostFormData("developer/post/image", formData)
        .then((response) => {
          resolve(`https://api.heroesraid.com${response.location}`);
        })
        .catch((error) => reject("Failed to upload image."));
    });

  return (
    <>
      <Header />
      <Breadcrumb>
        <h2>
          Create <span>News</span>
        </h2>
      </Breadcrumb>
      {hasAccess ? (
        <main>
          <div className="Container" style={{ padding: "16px 48px" }}>
            <div
              className="row align-items-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div className="col-xl-6 col-lg-8" style={{ paddingTop: "10px" }}>
                <div className="section-title title-style-three text-center mb-70">
                  <h2 style={headerStyle}>Title</h2>
                  <p>Enter the news article title.</p>
                  <input
                    style={{ width: "100%" }}
                    value={newsTitle}
                    onChange={(e) => setNewsTitle(e.target.value)}
                  ></input>
                  <h2 style={headerStyle}>Category</h2>
                  <p>Select a category for the news article.</p>
                  <select
                    style={{ width: "100%" }}
                    name="newsCategory"
                    id="newsCategory"
                    value={newsCategory}
                    onChange={(e) => setNewsCategory(e.target.value)}
                  >
                    <option value="announcement">Announcement</option>
                    <option value="patchnotes">Patch Notes</option>
                    <option value="event">Event</option>
                    <option value="news">News</option>
                  </select>
                  <h2 style={headerStyle}>Thumbnail</h2>
                  <p>Upload a thumbnail for the news article.</p>
                  <input
                    type="file"
                    name="Thumbnail"
                    accept="image/*"
                    onChange={(e) => setNewsThumbnail(e.target.files[0])}
                  ></input>
                  <h2 style={headerStyle}>Summary</h2>
                  <p>
                    Write a summary for the news article that will be used on
                    the front page and news index.
                  </p>
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL +
                      "assets/js/tinymce/tinymce.min.js"
                    }
                    scriptLoading={{ defer: true, async: false, delay: 0 }}
                    onInit={(evt, editor) =>
                      (summaryEditorRef.current = editor)
                    }
                    style={{ margin: "50px 10%" }}
                    onEditorChange={(content, editor) => {
                      setSummaryContent(content);
                    }}
                    initialValue="<p>Summary</p>"
                    init={{
                      height: 500,
                      menubar: false,
                      image_advtab: true,
                      image_uploadtab: true,
                      images_file_types: "jpg,png,bmp",
                      images_upload_handler: imageUploader,
                      image_title: true,
                      file_picker_types: "image",
                      automatic_uploads: true,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help | link image",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  <h2 style={headerStyle}>Body</h2>
                  <p>
                    Enter the full body for the news article that will be used
                    on its full page.
                  </p>
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL +
                      "assets/js/tinymce/tinymce.min.js"
                    }
                    scriptLoading={{ defer: true, async: false, delay: 0 }}
                    onInit={(evt, editor) => (bodyEditorRef.current = editor)}
                    style={{ margin: "50px 10%" }}
                    onEditorChange={(content, editor) => {
                      setBodyContent(content);
                    }}
                    initialValue="<p>Body</p>"
                    init={{
                      height: 500,
                      menubar: false,
                      image_advtab: true,
                      image_uploadtab: true,
                      images_file_types: "jpg,png,bmp",
                      images_upload_handler: imageUploader,
                      image_title: true,
                      file_picker_types: "image",
                      automatic_uploads: true,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help | link image",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  <input
                    style={{ margin: "50px", width: "300px" }}
                    type="button"
                    value="Create News"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : null}
      <Footer />
    </>
  );
}

export default News;
