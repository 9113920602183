import React from 'react'
import { Link } from 'react-router-dom';


function Breadcrumb(props) {
  return (
	 <section className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content text-center">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Breadcrumb