import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useHistory  } from "react-router-dom";

const propTypes = {
  binding: PropTypes.object,
  isHomePage: PropTypes.bool,
};

const defaultProps = {
  binding: null,
  isHomePage: false,
};

const CategoryToLinkCategory = (category) => {
  return category.replace(/\s+/g, "").toLowerCase();
};

const NewsItem = ({ binding, isHomePage, ...props }) => {
  if (!binding) {
    return null;
  }
  const history = useHistory();
  const linkTarget = process.env.PUBLIC_URL + "/news/" + binding.url;
  const categoryLink = `${
    process.env.PUBLIC_URL
  }/news?category=${CategoryToLinkCategory(binding.category)}`;
  const date = new Date(binding.created);
  const month = date.toLocaleDateString(undefined, { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const bindingDate = `${month} ${day}, ${year}`;

  return (
    <div className="col-lg-4 col-md-6">
      <div className="blog-post blog-style-two mb-50">
        <div className="blog-thumb mb-30">
          <Link to={linkTarget}>
            <img
              width="380"
              height="285"
              src={binding.thumbnail}
              alt={binding.title}
            />
          </Link>
        </div>
        <div className="blog-post-content">
          <h4>
            <Link to={linkTarget}>{binding.title}</Link>
          </h4>
          <div className="blog-meta">
            <ul>
              <li>
                <i className="far fa-clock" />
                {bindingDate}
              </li>
              <li>
                <i className="fas fa-tag" />
                <Link
                  onClick={(e) => {
                    if (!isHomePage) {
                      e.preventDefault();
                      history.replace(categoryLink);
                      history.go(0);
                    }
                  }}
                  to={categoryLink}
                >
                  {binding.category}
                </Link>
              </li>
            </ul>
          </div>
          <div dangerouslySetInnerHTML={{ __html: binding.summary }} />
        </div>
      </div>
    </div>
  );
};

NewsItem.propTypes = propTypes;
NewsItem.defaultProps = defaultProps;

export default NewsItem;
