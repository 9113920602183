import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutInner from "./aboutpage/aboutuspage/AboutInner";
import Breadcrumb from "./aboutpage/aboutuspage/Breadcrumb";
import { useParams } from "react-router-dom";
import { ApiGet } from "../Developer";

function NewsItem() {
  const { id } = useParams();
  const [newsTitle, setNewsTitle] = useState("");
  const [newsThumbnail, setNewsThumbnail] = useState(null);
  const [newsCategory, setNewsCategory] = useState("");
  const [summaryContent, setSummaryContent] = useState("");
  const [bodyContent, setBodyContent] = useState("");
  const [dateString, setDateString] = useState("");

  useEffect(() => {
    ApiGet(`resource/news/${id}`)
      .then((response) => {
        if (response.ok) {
          response.json().then((jsonResult) => {
            console.log(jsonResult);
            setNewsTitle(jsonResult.title);
            setNewsCategory(jsonResult.category);
            setSummaryContent(jsonResult.summary);
            setBodyContent(jsonResult.body);
			const date = new Date(jsonResult.created);
			const month = date.toLocaleDateString(undefined, { month: 'long'});
			const day = date.getDate();
			const year = date.getFullYear();
			setDateString(`${month} ${day}, ${year}`);
          });
        } else {
          //handle error
        }
      })
      .catch((error) => alert(error));
  }, []);

  return (
    <>
      <Header />
      <Breadcrumb>
        <h6>{newsCategory}</h6>
        <h2>
          <span>{newsTitle}</span>
        </h2>
		<h6>{dateString}</h6>
      </Breadcrumb>
      <main>
        <section className="inner-about-area fix">
          <div className="container">
            <div
              className="row align-items-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div class="news-inner" style={{ minHeight: "600px" }}>
                <div dangerouslySetInnerHTML={{ __html: summaryContent }} />
                <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default NewsItem;
