import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import ScrollToTop from "react-scroll-to-top";
import logo from "../assets/img/logo/logoSmaller.png";
import { Logout } from "../Developer";

export default function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();

  const HeaderLogout = () => {
    Logout();
    history.push("/");
    history.go(0);
  };

  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      // var mobileMenuContent = $('.menu-area .push-menu').html();
      // $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    if (window.user && window.user.jwt && window.user.jwt.role) {
      setIsLoggedIn(window.user);
    }
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
      }
    });
  }, []);

  const navigation = (
    <ul className="navigation">
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/release-schedule">Release Schedule</Link>
      </li>
      <li>
        <Link to="/news">News</Link>
      </li>
      <li>
        <Link to="/developer-stream">Developer Stream</Link>
      </li>
      <li>
        <Link to="/alpha">Alpha Sign-Up</Link>
      </li>
      {isLoggedIn ? (
        <li>
          <Link to="/" onClick={HeaderLogout}>
            Logout
          </Link>
        </li>
      ) : (
        <li>
          <Link to="/login">Login</Link>
        </li>
      )}
    </ul>
  );

  return (
    <header>
      <ScrollToTop smooth top="500" color="#000" />
      <div id="sticky-header" className="transparent-header menu-area">
        <div className="container-fluid s-container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler two">
                <i className="fas fa-bars" />
              </div>
              <div className="main-menu  menu-style-two">
                <nav>
                  <div className="logo">
                    <Link to="/">
                      <img
                        style={{ position: "absolute", top: "0px" }}
                        src={logo}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="navbar-wrap push-menu d-none d-lg-flex">{navigation}</div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <br />
                  <div className="menu-outer">{navigation}</div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

/* <li className='menu-item-has-children'><Link to="/release-schedule">Release Schedule</Link>
                            <ul className="submenu">
                              <li><Link to="/story">Story</Link></li>
                              <li><Link to="/gameplay">Gameplay</Link></li>
                              <li><Link to="/release-schedule">Release Schedule</Link></li>
                              <li><Link to="/aaron-lionheart">Aaron Lionheart</Link></li>
                            </ul>
                          </li> */
