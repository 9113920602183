import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutInner from "./aboutpage/aboutuspage/AboutInner";
import Breadcrumb from "./aboutpage/aboutuspage/Breadcrumb";
import { Chrono } from "react-chrono";
import { ApiGet } from "../Developer";

function ReleaseTimeline() {
  const [timelineData, setTimelineData] = useState(null);
  const [selectedTimelineIndex, setSelectedTimelineIndex] = useState(0);

  useEffect(() => {
    ApiGet('resource/timeline')
      .then((response) => {
        if (response.ok) {
          response.json().then((jsonResult) => {
            console.log(jsonResult);
            setSelectedTimelineIndex(jsonResult.selectedIndex);
            setTimelineData(jsonResult.entries);
          });
        } else {
          //handle error
        }
      })
      .catch((error) => alert(error));
  }, []);

  return (
    <>
      <Header />
      <Breadcrumb>
        <h2>
          <span>Release Schedule</span>
        </h2>
      </Breadcrumb>
      <main>
        <div className="container" style={{ minHeight: "600px" }}>
          <div className="newsletter-form" style={{ margin: "7.5px 0px" }}>
            <div
              style={{
                paddingTop: "20px",
                paddingBottom: "100px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
      <div style={{ width: "100%", height: "95vh" }}>
        {timelineData ? <Chrono hideControls activeItemIndex={selectedTimelineIndex} items={timelineData} mode="VERTICAL_ALTERNATING" /> : null }
      </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ReleaseTimeline;
