import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DeveloperStreamInner from './developerstream/DeveloperStreamInner'
import Breadcrumb from './aboutpage/aboutuspage/Breadcrumb'

function DeveloperStream() {
  return (
	<>
	<Header/>
	<Breadcrumb>
	<h2>Developer <span>Stream</span></h2>
	</Breadcrumb>
	<main>
	<DeveloperStreamInner/>	
	</main>
	<Footer/>
	</>
  )
}

export default DeveloperStream
