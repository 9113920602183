import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutInner from "./aboutpage/aboutuspage/AboutInner";
import Breadcrumb from "./aboutpage/aboutuspage/Breadcrumb";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

import {
  ApiPost,
  GetSearchParams,
  ProcessLoginToken,
  AnalyticEvent,
  GenerateAuthenticationHash,
} from "../Developer";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [formState, setFormState] = useState(0);
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const history = useHistory();

  const recaptchaChange = (value) => {    
    setRecaptchaValue(value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setpassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const performLogin = (event) => {
    event.preventDefault();
    authenticate();
  };

  const authenticate = () => {
    if(!recaptchaValue){
      setError("Complete the Recaptcha to continue");
      return;
    }
    setFormState(1);
    setError("");
    GenerateAuthenticationHash(username, password).then((hashedPassword) => {
      ApiPost("auth/register", { username, password: hashedPassword, email, recaptcha: recaptchaValue  })
        .then((response) => {
          if (response.ok) {
            response.json().then((jsonResult) => {
              ProcessLoginToken(jsonResult.tokenOrMessage);
              AnalyticEvent("Authentication", "Registration Success");
              history.push("/");
            });
          } else {
            AnalyticEvent("Authentication", "Registration Failure");
            if (response.status === 400) {
              response.json().then((jsonResult) => {
                if (jsonResult.result === 2) {
                  setError(
                    `That username is already taken try ${jsonResult.tokenOrMessage}`
                  );
                } else {
                  setError(jsonResult.tokenOrMessage);
                }
              });
            }
            setFormState(0);
          }
        })
        .catch((error) => {
          setFormState(0);
          console.log(error);
        });
    });
  };

  const formState1 = (
    <div
      style={{
        paddingTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px 0px 0px",
        }}
      >
         <p>Create a Heroes Raid Account, this is the same account you use to login to the game.</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px 0px 0px",
        }}
      >
      <p style={{ color: "Red" }}>{error}</p>
      </div>
      <form>
        <div
          className="column"
          style={{
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="newsletter-form-grp" style={{ margin: "20px" }}>
            <i className="far fa-login" />
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="newsletter-form-grp" style={{ margin: "20px" }}>
            <i className="far fa-login" />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div className="newsletter-form-grp" style={{ margin: "20px" }}>
            <i className="far fa-login" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <ReCAPTCHA sitekey="6LfQnXMkAAAAAFJehM93FRLqHi1h5bPluWYa7wIf" onChange={recaptchaChange} />
          <button onClick={performLogin} style={{ margin: "20px" }}>
            Register <i className="fas fa-login" />
          </button>
        </div>
      </form>
    </div>
  );

  const formState2 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0px 0px",
          minHeight: "700px",
        }}
      >
        <svg className="loadingspinner" viewBox="0 0 50 50">
          <circle
            className="spinnerpath"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <Breadcrumb>
        <h2>
          <span>Register</span>
        </h2>
      </Breadcrumb>
      <main>
        <div className="container" style={{ minHeight: "700px" }}>
          <div className="newsletter-form" style={{ margin: "7.5px 0px" }}>
            {formState === 0 ? formState1 : formState2}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Register;
