import React from 'react'
import Breadcrumb from './Breadcrumb'
import HeaderTwo from '../../components/Header';
import Footer from '../../components/Footer';
import BlogContent from './BlogContent'

function BlogDetails() {
  return (
	<>
	<Header/>
	<main>
	<Breadcrumb/>
	<BlogContent/>
	</main>
	<Footer/>
	</>
  )
}

export default BlogDetails