import React, { useEffect, useState } from "react";
import { AnalyticEvent } from '../Developer';

function Footer() {
  const [emailError, setEmailError] = useState("");
  const [fetchError, setFetchError] = useState("");
  const [email, setEmail] = useState("");
  const [formState, setFormState] = useState(0);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  async function postData(url = "", data = {}) {
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.ok;
    } catch {
      return false;
    }
  }

  const sendMessage = () => {
    setEmailError("");
    setFetchError("");
    let errors = false;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      AnalyticEvent("Email", "Newsletter Invalid Email");
      errors = true;
    }

    if (errors) {
      return;
    }

    setFormState(1);
    postData(
      "https://api.heroesraid.com/email/subscriptions/subscribe/marketing",
      {
        email: email,
      }
    ).then((success) => {
      if (success) {
        setFormState(2);
        AnalyticEvent("Email", "Newsletter Signup Success");
      } else {
        setFetchError(
          "There was an error, try again later."
          );
        setFormState(0);
        AnalyticEvent("Email", "Newsletter Signup Failure");
      }
    });
  };

  const formState1 = (
    <div className="newsletter-form" style={{ margin: "7.5px 0px" }}>
      <form>
        <div className="newsletter-form-grp">
          <i className="far fa-envelope" />
          <input
            type="email"
            placeholder="Enter your email..."
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <button onClick={sendMessage}>
          SUBSCRIBE <i className="fas fa-paper-plane" />
        </button>
      </form>
    </div>
  );

  const formState2 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "8px 0px",
        }}
      >
        <svg
          className="loadingspinner"
          viewBox="0 0 50 50"
          style={{ marginTop: "-10px" }}
        >
          <circle
            className="spinnerpath"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </>
  );

  const formState3 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "11px 0px",
        }}
      >
        <p
          style={{
            fontSize: "12pt",
            margin: "0px",
            color: "white",
            padding: "10px 6px",
          }}
        >
          You have been signed-up for the Heroes Raid Newsletter, Thank you.
        </p>
      </div>
    </>
  );

  return (
    <footer>
      <div className="footer-top footer-bg">
        {/* newsletter-area */}
        <div className="newsletter-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="newsletter-wrap">
                  <div className="section-title newsletter-title">
                    <h2>
                      Our <span>Newsletter</span>
                    </h2>
                    <p
                      style={{
                        fontSize: "9pt",
                        margin: "0px",
                        color: "#ffc107",
                      }}
                    >
                      {emailError}
                    </p>
                    <p
                      style={{
                        fontSize: "9pt",
                        margin: "0px",
                        color: "#ffc107",
                      }}
                    >
                      {fetchError}
                    </p>
                  </div>
                  {formState === 0
                    ? formState1
                    : formState === 1
                    ? formState2
                    : formState3}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* newsletter-area-end */}
      <div className="copyright-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="copyright-text">
                <p>
                  Copyright © 2023{" "}
                  <a href="https://aaronlionheart.com">Aaron Lionheart</a> All
                  Rights Reserved.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-none d-md-block"></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
